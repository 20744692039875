.dropdown-container {
    position: relative; /* Needed for absolute positioning of the dropdown */
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%; /* Align just below the button */
    right: 0; /* Align to the right of the parent */
    z-index: 50; /* Ensure it appears on top */
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    min-width: 12rem;
  }
  

  .overflow-x-scroll {
    display: block;
    overflow-x: auto;
  }
  
  /* Hide scrollbar for Chrome, Safari, and Edge */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none;
}
