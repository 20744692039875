
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');


.rowdies-light {
    font-family: "Rowdies", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .rowdies-regular {
    font-family: "Rowdies", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .rowdies-bold {
    font-family: "Rowdies", serif;
    font-weight: 700;
    font-style: normal;
  }
  